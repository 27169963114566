
import Syrah from './components/syrah';
import Stand from './components/stand';
import Monoprix from './components/monoprix';
import Giuseppe from './components/giuseppe';
import Model3D from './components/model3D';
import RouteChangeTracker from './components/RouteChangeTracker'
import Demo from './components/demo';
import './styles/App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import ROUTES from './config/routes';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-193548774-2";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  return (
    <>
      <Router>
        <RouteChangeTracker />
        <Switch>
          <Route path={ROUTES.HOME} exact component={Syrah} />
          <Route path={ROUTES.SYRAH} exact component={Model3D} />
          <Route path={ROUTES.STAND} exact component={Stand} />
          <Route path={ROUTES.STANDMONOP} exact component={Monoprix} />
          <Route path={ROUTES.GIUSEPPE} exact component={Giuseppe} />
          <Route path={ROUTES.DEMO} exact component={Demo} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
