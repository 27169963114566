import '../styles/model3D.scss';
import Header from './header';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

const list = require(`../model/ListStand.json`).list;
const length = list.length;

const Stand = () => {
  let { id } = useParams();

  const Next = () => {
    let newId = "";
    if (id == length) {
      newId = 1;
    } else {
      newId = parseInt(id) + 1;
    }
    return <Link to={`/monop/${newId}`}><button className="button is-primary">Next</button></Link>
  }

  const Prev = () => {
    let newId = "";
    if (id == 1) {
      newId = length;
    } else {
      newId = parseInt(id) - 1;
    }
    return <Link to={`/monop/${newId}`}><button className="button is-primary">Prev</button></Link>
  }

  return (
    <>
      <Header/>
      <div>
        <model-viewer src={list[id-1].glb}
                    ios-src={list[id-1].usdz}
                    poster={list[id-1].poster} camera-controls autoplay ar ar-modes="scene-viewer webxr"></model-viewer>
      </div>
      <div className="buttons is-centered">
        {Prev()}
        {Next()}
      </div>
    </>
    
  )
}

export default Stand;