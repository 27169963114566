
import '../styles/giuseppe.scss';
import Header from './header';
import Card from './card';
import {
  useParams
} from "react-router-dom";
import { getWine } from '../services/db';
import React, { useState, useEffect } from 'react';
import MobileDetect from 'mobile-detect';

const Demo = () => {
  let { id } = useParams();
  const [wine, setWine] = useState("");
  const [newid, setNewid] = useState(id);
  const modelViewer = React.useRef();
  const audio = React.useRef();
  const overlay = React.useRef();
  let type = new MobileDetect(window.navigator.userAgent);

  const playAudio = async () => {
    modelViewer.current.play();
    audio.current.play();
  }

  const renderViewer = () => {
    if (type.os() === "iOS") {
      return (<div className="viewer">
        <a className="ios" rel="ar" href="https://storage.googleapis.com/webar-sb/Animations/Andreas_Demo_ios_5.reality"><img class="image-model" src="https://storage.googleapis.com/prod_sb/AndreasLarsson/Reality/poster.jpg" alt="" data-hires-status="pending" />
          <div className="text-model">Ouvrir en réalité augmentée</div>
        </a>
      </div>)
    } else if (type.os() === "AndroidOS") {
      return (<div className="viewer">
        <a className="android" href="intent://arvr.google.com/scene-viewer/1.0?file=https://storage.googleapis.com/webar-sb/Animations/AndreasL_Demo_4.glb&sound=https://storage.googleapis.com/webar-sb/Animations/Andreas_Demo_V5.mp3&disable_occlusion=true&mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;"><img class="image-model" src="https://storage.googleapis.com/prod_sb/AndreasLarsson/Reality/poster.jpg" alt="" data-hires-status="pending" />
          <div className="text-model">Ouvrir en réalité augmentée</div>
        </a>
      </div>)
    } else {
      return (<div className="viewer">
        Device pas compatible
      </div>)
    }
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="presentation">
        <h1 style={{
          fontSize: "4em",
          fontWeight: "bold",
          marginTop: "50px",
        }}>Demo</h1>
      </div>
      {renderViewer()}


      <div className="footer" style={{ position: "absolute", bottom: 0, width: "100vw" }}>
        <div className="copyright">Copyright ©  2024 <a href="https://yzar.fr">YZAR</a>. All rights reserved.</div>
      </div>
    </div>

  )
}

export default Demo;