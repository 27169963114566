import '../styles/header.scss';
import Logo from '../img/Logo.png';

const Header = () => {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
       
          <a className="navbar-item" href="https://smartbottle.wine" target="_blank">
          <img src={Logo} />
          </a>
          
        </div>
      </div>
    </nav>
  )
}

export default Header;