import '../styles/model3D.scss';
import Header from './header';
import Card from './card';
import {
  useParams
} from "react-router-dom";
import { getWine } from '../services/db';
import React, { useState, useEffect } from 'react';

const Model3D = () => {
  let { id } = useParams();
  const [wine, setWine] = useState("");
  const [newid, setNewid] = useState(id);

  useEffect(() => {
    (async () => {
      try {
        const data = await getWine(newid);
        setWine(data);
      } catch (error) {
        console.log(error);
      }
    })()
  }, [newid, id]);
  

  return (
    <>
      <Header/>
      <div>
        <model-viewer src="https://storage.googleapis.com/webar-sb/Animations/Syrah/Syrah.glb"
                    ios-src="https://storage.googleapis.com/webar-sb/Animations/Syrah/Syrah.usdz"
                    poster="https://storage.googleapis.com/webar-sb/Animations/Syrah/Syrah.png" camera-controls autoplay animation-name="KinetixAnimCharacter 0" ar ar-modes="scene-viewer webxr"></model-viewer>
      </div>
      <Card wine={wine}/>
    </>
    
  )
}

export default Model3D;