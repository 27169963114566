const config = {
  REACT_APP_ENV: process.env.REACT_APP_ENV || 'development',

  production: {
    API_DB_URL: 'https://api-db-oaqthzkpda-ew.a.run.app',
    API_AR_URL: 'https://api-ar-oaqthzkpda-ew.a.run.app',
  },

  test: {
    API_DB_URL: 'https://test-api-db-oaqthzkpda-ew.a.run.app',
    //API_AR_URL: 'https://test-ar-oaqthzkpda-ew.a.run.app',
    API_AR_URL: 'https://api-ar-oaqthzkpda-ew.a.run.app',
  },

  development: {
    API_DB_URL: 'http://localhost:3003',
    //API_AR_URL: 'http://localhost:3004',
    API_AR_URL: 'https://api-ar-oaqthzkpda-ew.a.run.app',
  }
}

export default config;