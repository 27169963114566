import React, { useState, useEffect } from 'react';
import '../styles/card.scss';
import Buy from '../img/cart.png';

const Card = (wineinfo) => {
  const [wine, setWine] = useState(null);

  useEffect(() => {
    setWine(wineinfo.wine);
  });

  return (
    <div className="card">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">Web AR Demo</p>
                <p className="subtitle is-6">France</p>
              </div>
            </div>

            <div className="buttons">
              <a className="button buttonSyrah is-fullwidth" href="https://smartbottle.wine" target="_blank">
                Acheter
                <img className="image is-16x16" src={Buy} alt="cart"/>
              </a>
            </div>
          </div>
    </div>
  )
}

export default Card;