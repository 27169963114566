import '../styles/model3D.scss';
import Header from './header';
import Card from './card';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { getWine } from '../services/db';
import React, { useState, useEffect } from 'react';

const Syrah = () => {
  return (
    <>
      <div className="home">
        <div className="content">
          <h1>SMARTBOTTLE</h1>
          <div className="subtitle">Bienvenue dans le futur, venez découvrir avec nous une nouvelle manière de découvrir le vin. Grâce à la réalité augmentée vous basculez dans un nouveau monde où votre seule limite est votre imagination.</div>
          <div className="examples">
          <Link to={`/giuseppe`}><button className="button">Découvrir</button></Link>
        </div>
      <div className="mobile">
        <div>Retrouvez toutes nos offres sur notre application</div>
        <div className="logo-download">
          <img className="logo" src="./apple.png" alt="" />
          <img className="logo" src="./android.png" alt="" />
        </div>
      </div>
        </div>
        
        <div className="footer ">
        <div className="copyright">Copyright ©  2022 <a href="https://smartbottle.wine">SMARTBOTTLE</a>. All rights reserved.</div>
      </div>
      </div>
    </>
    
  )
}

export default Syrah;