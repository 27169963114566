
import '../styles/giuseppe.scss';
import Header from './header';
import Card from './card';
import {
  useParams
} from "react-router-dom";
import { getWine } from '../services/db';
import React, { useState, useEffect } from 'react';
import MobileDetect from 'mobile-detect';

const Giuseppe = () => {
  let { id } = useParams();
  const [wine, setWine] = useState("");
  const [newid, setNewid] = useState(id);
  const modelViewer = React.useRef();
  const audio = React.useRef();
  const overlay = React.useRef();
  let type = new MobileDetect(window.navigator.userAgent);

  const playAudio = async () => {
    modelViewer.current.play();
    audio.current.play();
  }

  const renderViewer = () => {
    if (type.os() === "iOS") {
      return (<div className="viewer">
        <a className="ios" rel="ar" href="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Giuseppe_V2.20.reality"><img class="image-model" src="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/poster_giuseppe.jpg" alt="" data-hires-status="pending" />
          <div className="text-model">Ouvrir en réalité augmentée</div>
        </a>
      </div>)
    } else if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return (<div className="viewer">
        <a className="ios" rel="ar" href="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Giuseppe_V2.20.reality"><img class="image-model" src="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/poster_giuseppe.jpg" alt="" data-hires-status="pending" />
          <div className="text-model">Ouvrir en réalité augmentée</div>
        </a>
      </div>)
    } else if (type.os() === "AndroidOS") {
      return (<div className="viewer">
        <a className="android" href="intent://arvr.google.com/scene-viewer/1.0?file=https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Giuseppe_V2.2.glb&sound=https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Sound_Giuseppe.mp3&mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;"><img class="image-model" src="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/poster_giuseppe.jpg" alt="" data-hires-status="pending" />
          <div className="text-model">Ouvrir en réalité augmentée</div>
        </a>
      </div>)
    } else {
      return (<div className="viewer">
        <model-viewer id="giuseppe" ref={modelViewer} bounds="tight"
          src="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Giuseppe_V2.2.glb?sound=https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Sound_Giuseppe.mp3"
          ios-src="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Giuseppe_V2.2.usdz"
          poster="https://storage.googleapis.com/prod_sb/Assets/Animations/animbdx_poster.png"
          ar ar-modes="scene-viewer webxr quick-look" camera-controls shadow-softness="0.23" animation-name="Animation" ar-scale="fixed" disable-zoom autoplay>
          <div className="progress-bar hide" slot="progress-bar">
            <div className="update-bar"></div>
          </div>
          <button slot="ar-button" id="ar-button">
            Activer l'AR
          </button>
        </model-viewer>
      </div>)
    }
  }

  return (
    <>
      <div className="presentation">
        <img className="logo-giu" src="https://storage.googleapis.com/webar-sb/Animations/Giuseppe/Logo_Giuseppe_V3.png" alt="" />
        <div className="subtitle">Suivez Signore Giuseppe à la découverte  des vins d'Italie et de ses terroirs passionnants grâce à la réalité augmentée et à son site Internet !</div>
      </div>
      <div className="decouvrir">
        <a href="https://signoregiuseppewines.com/" target="_blank">Découvrir le site Signore Giuseppe </a>
      </div>
      {renderViewer()}
      <div className="mobile">
        <div>Retrouvez toutes nos offres sur notre application</div>
        <div className="logo-download">
          <a href="https://apps.apple.com/us/app/smartbottle/id1456744355"><img className="logo" src="./apple.png" alt="" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.smartbottle.android&hl=fr"><img className="logo" src="./android.png" alt="" /></a>
        </div>
      </div>


      <div className="footer">
        <div className="copyright">Copyright ©  2022 <a href="https://smartbottle.wine">SMARTBOTTLE</a>. All rights reserved.</div>
      </div>
    </>

  )
}

export default Giuseppe;