import axios from 'axios';
import config from '../config';
const { API_AR_URL } = config[config.REACT_APP_ENV];

axios.defaults.headers.common = {'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIsImlhdCI6MTYxMDQ0MjY2NDIwMSwidHlwZV9vZl91c2VyIjoiYWRtaW5zIiwicm9sZSI6InN1cGVyYWRtaW4iLCJleHAiOjE2MTA0NDI2Njc4MDF9.ES2SVhIwtr8YeiZTqbkqdpwVyzYQIq9cqdYo0Ce0eTg `}

export const getWine = async (id) => {
  try {
    const { data } = await axios.get(`${API_AR_URL}/v6/metadata/FR/${id}`);
    return data;
  } catch (error) {
    console.error('Get wine', error);
    throw Error(error);
  }
}



